// Run this example by adding <%= javascript_pack_tag 'asset_submission' %> to your desired file.

import React from 'react'
import ReactDOM from 'react-dom'
import AssetForm from '../event_submission/EventForm'

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.querySelectorAll('.form-container')

  Array.from(rootElement).forEach((element) => {
    ReactDOM.render(<AssetForm />, element)
  })
})
